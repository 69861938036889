import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function formatTime(time:string){
  const date = new Date(time);

// 获取年、月、日、时、分、秒
const year = date.getFullYear(); // 获取四位年份
const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份，月份从 0 开始，所以加 1
const day = date.getDate().toString().padStart(2, '0'); // 获取日
const hours = date.getHours().toString().padStart(2, '0'); // 获取小时
const minutes = date.getMinutes().toString().padStart(2, '0'); // 获取分钟
const seconds = date.getSeconds().toString().padStart(2, '0'); // 获取秒

// 构建目标格式的字符串
const formattedDate = `${year.toString()}-${month}-${day} ${hours}:${minutes}:${seconds}`;

return formattedDate
}
